/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    shape, string, arrayOf, func, object,
} from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import AfterProductBannerBlock from './Partials/AfterProductBannerBlock';
import noop from '../../../../../helpers/noop';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import MultipleCTABanner from '../GraphqlCatHeaderContainer/Partials/MultipleCTABanner/MultipleCTABanner';

const styles = () => ({
    container: {
        position: 'relative',
    },
    panel: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
    },
    bannerImage: {
        width: '100%',
        display: 'block',
        margin: '0 auto 30px auto',
    },
});
const GraphqlAfterProductBanner = ({
    bannerBlock, trackEvent, classes, device,
}) => {
    const trackClickEvent = () => {
        const { tracking_event_category = '', tracking_event_label = '', tracking_event_action = '' } = bannerBlock;
        if (tracking_event_label) {
            trackEvent({
                eventCategory: tracking_event_category,
                eventAction: tracking_event_action,
                eventLabel: tracking_event_label,
            });
        }
    };
    if (bannerBlock?.banner_with_multiple_ctas && bannerBlock?.banner_with_multiple_ctas?.banner_group.length > 0) {
        return (
            <MultipleCTABanner multiCtaBlock={bannerBlock?.banner_with_multiple_ctas} ctaHeadlineStyles={bannerBlock?.headline_font} />
        );
    }

    if (bannerBlock?.link?.href) {
        const href = bannerBlock.link.href;
        return (
            (href.indexOf('http://') === 0 || href.indexOf('https://') === 0)
                ? (
                    <a href={href} onClick={() => trackClickEvent()}>
                        <AfterProductBannerBlock bannerBlock={bannerBlock} classes={classes} device={device} />
                    </a>
                )
                :  (
                    <Link to={href} onClick={() => trackClickEvent()}>
                        <AfterProductBannerBlock bannerBlock={bannerBlock} classes={classes} device={device} />
                    </Link>
                )
        );
    }
    return (
        <AfterProductBannerBlock bannerBlock={bannerBlock} classes={classes} device={device} />
    );
};

GraphqlAfterProductBanner.propTypes = {
    classes: object.isRequired,
    trackEvent: func,
    bannerBlock: shape({
        name: string,
        link: shape({
            title: string,
            href: string.isRequired,
        }).isRequired,
        viewport: arrayOf(
            shape({
                uid: string,
                _content_type_uid: string,
            }),
        ),
        imported_data: string,
    }).isRequired,
    device: string.isRequired,
};

GraphqlAfterProductBanner.defaultProps = {
    trackEvent: noop,
};

const mapStateToProps = (state) => ({
    device: getSSRDeviceType(state),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, null),
);

export default enhance(GraphqlAfterProductBanner);
