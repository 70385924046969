/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { array, shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import  { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({

    multiCtaTitle: {
        textAlign: 'center',
        fontSize: '16px',
        marginLeft: '8px',
        fontFamily: 'LatoBold',
        width: '250px',
    },
    ctaBlockContainer: {
        display: 'flex',
        border: '1px solid lightgray',
        [theme.breakpoints.down(769)]: {
            flexDirection: 'column',
        },
    },

    ctabuttons: {
        marginLeft: '0px',
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '16px',
        },
    },

    ctaImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
        },
        '& img': {
            width: '100%',
        },
    },
    ctaImageContainer: {
        paddingBottom: '35px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexWrap: 'wrap',
        },
    },

    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

}));

const MultipleCTABanner = ({
    multiCtaBlock, ctaHeadlineStyles,
}) => {
    const classes = useStyles();
    const title = multiCtaBlock?.title;
    const backgroundColor = multiCtaBlock?.background_color?.color;
    const dispatch = useDispatch();
    const trackableLinkHandler = (d) => {
        dispatch(trackEvent({
            eventCategory: d.tracking_event_category || '',
            eventAction: d.tracking_event_action || '',
            eventLabel: d.tracking_event_label || '',
        }));
    };
    const styleOverride = (fonts = '') => ({
        fontFamily: fonts || '',
    });
    const headlineFont = styleOverride(ctaHeadlineStyles?.replace(/font-family: |;/g, ''));

    return (
        <div style={{ backgroundColor }}>
            <div className={classes.ctaBlockContainer}>
                <div className={classes.titleContainer}>
                    <div className={classes.multiCtaTitle}><h2 style={headlineFont}>{title}</h2>
                    </div>
                </div>
                <div className={classes.ctaImageContainer}>
                    {multiCtaBlock.banner_group.length && multiCtaBlock.banner_group.map((d) => (
                        <div
                            className={classes.ctaImage}
                            role="presentation"
                            onClick={() => { trackableLinkHandler(d); }}
                            onKeyDown={() => { trackableLinkHandler(d); }}
                        >
                            <Link
                                to={d.cta_button.href}
                            >
                                <img
                                    src={d.image.url}
                                    alt="img"
                                />
                            </Link>

                            <div className={classes.cta}>

                                <Link
                                    className={classes.ctabuttons}
                                    to={d.cta_button.href}
                                >
                                    {d.cta_button.title}
                                </Link>

                            </div>

                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
};

MultipleCTABanner.propTypes = {
    multiCtaBlock: shape({
        banner_group: array,
    }).isRequired,
    ctaHeadlineStyles: string.isRequired,
};

export default MultipleCTABanner;
