/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import {
    arrayOf, bool, func, object, string, shape, array,
} from 'prop-types';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import GenericBannerHeader from '../../../../ContentTypeComponents/GenericBanner/GenericBanner';
import GraphqlAfterProductBanner from '../GraphqlAfterProductBanner/GraphqlAfterProductBanner';
import GraphqlCatHeaderContainer from '../GraphqlCatHeaderContainer/GraphqlCatHeaderContainer';
import NUpRow from '../../../../ContentTypeComponents/NUp/NUp';
import NupSimple from '../../../../ContentTypeComponents/NupSimple/NupSimple';
import HeroSingle from '../../../../ContentTypeComponents/HeroSingle/HeroSingle';
import ABTest from '../../../AB_Testing/hocs/ABTesting';
import { getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import BlockHeading from '../../../../ContentTypeComponents/BlockHeading';

const LoadableSubNavCollection = loadable(() => import(/* webpackChunkName: "SubNavCollection" */ '../SubNavCollection/SubNavCollection'));
const LoadableEmailRecommendations = loadable(() => import(/* webpackChunkName: "EmailRecommendations" */ '../EmailRecommendations/EmailRecommendations'));

const GraphqlCategoryTopSection = ({
    category_blocks,
    subNavCollection,
    brandName,
    samedayCutoff,
    featureFlags,
    trackEventAction,
    ssrDeviceType,
    bannerPositionTop,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    return (
        <>
            {category_blocks?.map((block) => {
                const blockKey = Object.keys(block)[0];
                switch (blockKey) {
                    case 'imoc':
                        return <GraphqlCatHeaderContainer categoryHeader={category_blocks} brand={brandName} samedayCutoff={samedayCutoff} featureFlagIsMobileCollectionTabbed={featureFlags['is-mobile-collection-tabbed']} trackEvent={trackEventAction} />;

                    case 'generic_banner':
                        // isCollectionPage = PLP or category page
                        return <GenericBannerHeader data={block?.generic_banner} isCollectionPage />;

                    case 'banner':
                        return (block?.banner && bannerPositionTop) && <GraphqlAfterProductBanner bannerBlock={block?.banner} trackEvent={trackEventAction} device={ssrDeviceType} />;

                    case 'email_recommendations':
                        return <LoadableEmailRecommendations cmsData={block?.email_recommendations} />;

                    case 'n_up':
                        return (
                            <>
                                {block?.n_up?.heading && <BlockHeading data={block?.n_up} />}
                                <NUpRow data={block?.n_up} categoryTop />
                            </>
                        );

                    case 'n_up_simple':
                        return (
                            <>
                                {block?.n_up_simple?.heading && <BlockHeading data={block?.n_up_simple} />}
                                <NupSimple data={block?.n_up_simple} />
                            </>
                        );

                    case 'sub_nav':
                        return <LoadableSubNavCollection data={block?.sub_nav} products={subNavCollection?.products} />;
                    case 'hero_single':
                        return <HeroSingle data={block[blockKey]} />;
                    case 'a_b_testing_block':
                        return <ABTest block={block[blockKey]} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />;

                    default:
                        return null;
                }
            })}
        </>
    );
};

GraphqlCategoryTopSection.propTypes = {
    category_blocks: arrayOf(shape({
        generic_banner: shape({
            reference: arrayOf(shape({
                device: string,
                title: string,
                reference: arrayOf(shape({
                    banner_clickable: bool,
                    title: string,
                    image: shape({
                        url: string,
                        title: string,
                    }),
                })),
            })),
        }),
        imoc: shape({
            imoc_group: arrayOf(shape({
                copy: string,
                header: string,
                expose_copy: bool,
            })),
        }),
    })),
    subNavCollection: shape({
        products: array.isRequired,
    }),
    brandName: string.isRequired,
    samedayCutoff: object,
    featureFlags: bool,
    trackEventAction: func.isRequired,
    ssrDeviceType: string,
    bannerPositionTop: string,
};

GraphqlCategoryTopSection.defaultProps = {
    category_blocks: [],
    subNavCollection: {},
    samedayCutoff: {},
    featureFlags: false,
    ssrDeviceType: '',
    bannerPositionTop: '',
};
export default GraphqlCategoryTopSection;
