/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, object, shape, string,
} from 'prop-types';
import Panel from '../../../../../ContentTypeComponents/Panel';

const AfterProductBannerBlock = ({ bannerBlock, classes, device }) => {
    const overlays = bannerBlock?.overlays;
    const imgSrc = device === 'desktop' ? bannerBlock?.image : bannerBlock?.image_mobile;
    return (
        imgSrc?.url
            ? (
                <div className={classes.container}>
                    <img
                        src={imgSrc?.url}
                        alt={imgSrc?.description || 'banner'}
                        className={classes.bannerImage}
                    />
                    {overlays?.length > 0 && (
                        <div className={classes.panel}>
                            <Panel
                                data={{ overlays }}
                            />
                        </div>
                    )}
                </div>
            ) : null // component need to return something else it's throwing error on mobile
    );
};

AfterProductBannerBlock.propTypes = {
    classes: object.isRequired,
    bannerBlock: shape({
        image: shape({
            url: string,
            title: string,
        }),
        image_mobile: shape({
            url: string,
            title: string,
        }),
        display_on_mobile: bool,
    }),
    device: string.isRequired,
};
AfterProductBannerBlock.defaultProps = {
    bannerBlock: {
        image: {
            url: '',
            title: '',
        },
        image_mobile: {
            url: '',
            title: '',
        },
        display_on_mobile: false,
    },
};

export default AfterProductBannerBlock;
